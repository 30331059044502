// @ts-nocheck
import { useEffect, useState } from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import axios from "axios";
import { URL_BASE } from "../../config/config";
import { Card } from "../../components/ui/Card";
import { Modal } from "../../components/ui/Modal";
import Swal from "sweetalert2";
import { PrivateLayout } from "../../layouts/PrivateLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faFilter, faDownload } from "@fortawesome/free-solid-svg-icons";
import { IFormInput } from "./Formularios";
import Formulario from "./Formularios";
import Table from "./Table";
import Filtros from "./Filtros";

export const ConsumosLuz = () => {
    const methods = useForm<IFormInput>();
    const { register, handleSubmit, reset, setValue, formState: { errors }, clearErrors } = methods;
    const [consumos, setConsumos] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [direcciones, setDirecciones] = useState([]);
    const [isModalShow, setIsModalShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [titulo, setTitulo] = useState('');
    const [mesActual, setMesActual] = useState(new Date().getMonth() + 1);
    const [yearActual, setYearActual] = useState(new Date().getFullYear());
    const [estadoActual, setEstadoActual] = useState('');
    const [showFilters, setShowFilters] = useState(false);
    const [params, setParams] = useState({
        opciones: 'listPage',
        orderPor: 'cliente_id',
        order: 'asc',
        perpage: 10,
        filters: {},
        page: 1
    });
    const [paginate, setPaginate] = useState([]);

    const fetchData = async (month: number, year: number, clientId: number | null, estado: string | null) => {
        try {
            const { data } = await axios.get(`${URL_BASE}/lecturas?opcion=paginate&month=${month}&year=${year}${clientId ? `&cliente_id=${clientId}` : ''}${estado ? `&estado_lectura=${estado}` : ''}`);
            setConsumos(data.data);
            setPaginate(data.links);
        } catch (error) {
            console.error("Error fetching consumption data:", error);
        }
    }

    const getClientes = async () => {
        try {
            const { data } = await axios.get(`${URL_BASE}/clientes?opcion=get`);
            setClientes(data);

        } catch (error) {
            console.error("Error fetching clients:", error);
        }
    }

    const getDirecciones = async (clientId: number) => {
        try {
            const { data } = await axios.get(`${URL_BASE}/clientes?opcion=first&filtro[cliente_id]=${clientId}`);
            setDirecciones(data.direcciones);
        } catch (error) {
            console.error("Error fetching addresses:", error);
        }
    }

    useEffect(() => {
        getClientes();

        fetchData(mesActual, yearActual, null, null);

    }, []);

    const newConsumo = (id: string | number = 0) => {
        const consumoId = Number(id); // Convert to number
        if (consumoId > 0) {
            setIsEdit(true);
            const consumoEdit = consumos.find((consumo) => consumo.lectura_id === consumoId);
            if (consumoEdit) {
                const client_id = consumoEdit.direccion.cliente.cliente_id;

                // Prepare the data object
                const initialData = {
                    lectura_id: consumoEdit.lectura_id,
                    client_id: client_id,
                    direccion_id: consumoEdit.direccion_id,
                    fecha_lectura: consumoEdit.fecha_lectura ? consumoEdit.fecha_lectura.substring(0,10) : '',
                    lectura_actual: consumoEdit.lectura_actual,
                    lectura_anterior: consumoEdit.lectura_anterior,
                    consumo_kwh: consumoEdit.consumo_kwh,
                    estado_lectura: consumoEdit.estado_lectura,
                };

                // Use reset to populate the form
                reset(initialData);

                // Fetch the addresses for this client
                getDirecciones(client_id);
            }
        } else {
            reset();
        }
        setTitulo(consumoId > 0 ? 'Editar Consumo' : 'Nuevo Consumo');
        setIsModalShow(true);
    }


    const closeModal = () => {
        reset();
        clearErrors();
        setIsEdit(false);
        setIsModalShow(false);
        setDirecciones([]);
    }

    const onSubmit: SubmitHandler<IFormInput> = async (data) => {
        try {
            Swal.fire({
                title: 'Esperando...',
                text: 'Guardando el consumo!',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            const response = isEdit ?
                await axios.put(`${URL_BASE}/lecturas/${data.lectura_id}`, data) :
                await axios.post(`${URL_BASE}/lecturas`, data);

            Swal.close();

            if (response.status === 201 || response.status === 200) {
                Swal.fire(
                    'Guardado!',
                    'El consumo ha sido guardado.',
                    'success'
                );
                reset();
                closeModal();
                fetchData(mesActual, yearActual, null);
            }
        } catch (e) {
            //console.log(e.response.data.message)
            const errors = e.response.data.message ? e.response.data.message : 'Hubo un error al intentar guardar el consumo.'
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: errors,
            });
        }
    }

    const handleMonthChange = (event: { target: { value: string; }; }) => {
        const month = parseInt(event.target.value);
        setMesActual(month);
        fetchData(month, yearActual, null);
    }

    const handleYearChange = (event: { target: { value: string; }; }) => {
        const year = parseInt(event.target.value);
        setYearActual(year);
        fetchData(mesActual, year, null);
    }

    const handleFilterChange = (client: any) => {
        // Handle filter client change in the filters
        fetchData(mesActual, yearActual, client?.value);
    }
    const handleEstadoChange = (event: { target: { value: string; }; }) => {
        const estado = event.target.value;
        fetchData(mesActual, yearActual, null, estado);
    }
    const handleClientChange = (clientId: number) => {
        setValue('client_id', clientId);
        getDirecciones(clientId);
    }

    const handleReset = () => {
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        setMesActual(currentMonth);
        setYearActual(currentYear);
        fetchData(currentMonth, currentYear, null);
    }

    const downloadData = async () => {
        try {
            const response = await axios({
                url: `${URL_BASE}/exportar-lecturas?month=${mesActual}&year=${yearActual}`, // Endpoint del backend con parámetros
                method: 'GET',
                responseType: 'blob', // Importante para manejar la respuesta como un archivo
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const nombreArchivo = `lecturas-${mesActual}-${yearActual}.xlsx`;
            link.href = url;
            link.setAttribute('download', nombreArchivo); // Nombre del archivo
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error descargando el archivo', error);
            alert('Hubo un error al intentar descargar el archivo. Por favor, inténtalo de nuevo.');
        }
    };

    const resetFiltro = async () => {
        const filters = {
            ...params,
            filters: {},
            page: 1,
        };
        setParams(filters);
        fetchData(mesActual, yearActual, null);
    }

    const handleChangePage = async (page: number) => {
        const filters = {
            ...params,
            page: page,
        };
        setParams(filters);

        const {data , status } = await axios.get(`${URL_BASE}/lecturas?opcion=paginate&page=${page}&month=${mesActual}&year=${yearActual}`)

        if (status === 200) {
            setConsumos(data.data);
            setPaginate(data.links);
        }
    };

    return (
        <PrivateLayout>
            <div className="container mt-5">
                <Card
                    title="Lectura Luz"
                    toolActions={() => (
                        <div className="btn-group">
                            <button onClick={() => newConsumo()} className="btn btn-warning btn-sm">
                                <FontAwesomeIcon icon={faPlus} /> Nuevo
                            </button>
                            <button onClick={() => setShowFilters(!showFilters)} className="btn btn-info btn-sm">
                                <FontAwesomeIcon icon={faFilter} /> Filtrar
                            </button>
                            <button onClick={downloadData} className="btn btn-success btn-sm">
                                <FontAwesomeIcon icon={faDownload} /> Descargar
                            </button>
                        </div>
                    )}
                >
                    {showFilters && (
                        <Filtros
                            handleMonthChange={handleMonthChange}
                            handleYearChange={handleYearChange}
                            handleFilterChange={handleFilterChange}
                            handleEstadoChange={handleEstadoChange}
                            handleReset={resetFiltro}
                            mesActual={mesActual}
                            yearActual={yearActual}
                            clientes={clientes}
                            selectedClient={null}
                            estadoActual={estadoActual}
                        />
                    )}

                    <Table
                        consumos={consumos}
                        newConsumo={newConsumo}
                        paginate={paginate}
                        handleChangePage={handleChangePage}
                    />
                </Card>
            </div>

            <Modal show={isModalShow} handleClose={closeModal} title={titulo}>
                <FormProvider {...methods}>
                    <Formulario
                        isEdit={isEdit}
                        onSubmit={handleSubmit(onSubmit)}
                        errors={errors}
                        clientes={clientes}
                        direcciones={direcciones}
                        handleClientChange={handleClientChange}
                    />
                </FormProvider>
            </Modal>
        </PrivateLayout>
    );
}

export default ConsumosLuz;







