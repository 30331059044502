//import {UserFile} from "./UserFile";
//import pluma from "../Assets/images/pluma.png";
import Swal from "sweetalert2";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../slice/LoginSlice";

export const Header = () => {

   // const user = JSON.parse(localStorage.getItem('user') || '{}');
    const user = useSelector((state: any) => state.login.user)
    const dispatch = useDispatch();

    const handleLogout = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '¡Sí, salir!'
        }).then((result) => {
            if (result.isConfirmed) {
                // @ts-ignore
                Swal.fire({
                    title: 'Saliendo...',
                    timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        Swal.showLoading()
                    },
                }).then(() => {
                    dispatch(logout());
                })
            }
        })
    };


    return (
        <div id="kt_app_header" className="app-header">
            <div className="app-container container-fluid d-flex justify-content-between" id="kt_app_header_container">
                <div className="d-flex align-items-center d-lg-none ms-n3 me-2" title="Show sidebar menu">
                    <div className="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_sidebar_mobile_toggle">
                        <span className="svg-icon svg-icon-2">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor"/>
                                <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="currentColor"/>
                            </svg>
                        </span>
                    </div>
                </div>
                <div className="d-flex  justify-content-between flex-lg-grow-1" id="kt_app_header_wrapper">
                <div className="app-header-menu app-header-mobile-drawer align-items-stretch"
                     data-kt-drawer="true" data-kt-drawer-name="app-header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="250px" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_app_header_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="{default: 'append', lg: 'prepend'}" data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}">

                </div>

                    <div className="app-navbar flex-shrink-0">
                        <div className="app-navbar-item  ms-1 ms-md-3" style={{marginLeft:'25px'}}>

                            <div
                                className="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px"
                                id="kt_activities_toggle">
                                <button className="btn btn-danger" onClick={() => handleLogout()}>Salir</button>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
}