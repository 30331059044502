// src/components/clientes/ToolActionsClientes.tsx

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFilter, faUpload } from "@fortawesome/free-solid-svg-icons";

interface ToolActionsProps {
    onNew: () => void;
    onImport: () => void;
    onToggleFilters: () => void;
}

const ToolActionsClientes: React.FC<ToolActionsProps> = ({ onNew, onImport, onToggleFilters }) => {
    return (
        <div className="btn-group">
            <button onClick={onNew} className="btn btn-warning btn-sm">
                <FontAwesomeIcon icon={faPlus} /> Nuevo
            </button>

            {/*
            <button onClick={onImport} className="btn btn-success btn-sm">
                <FontAwesomeIcon icon={faUpload} /> Importar
            </button>
            */}

            <button onClick={onToggleFilters} className="btn btn-info btn-sm">
                <FontAwesomeIcon icon={faFilter} /> Filtros
            </button>
        </div>
    );
};

export default ToolActionsClientes;
