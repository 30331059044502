import React from "react";
import Select from 'react-select';
import {meses,years} from "../../data/meses";

interface FiltrosProps {
    handleMonthChange: (event: { target: { value: string } }) => void;
    handleYearChange: (event: { target: { value: string } }) => void;
    handleFilterChange: (client: any) => void;
    handleEstadoChange: (estado: any) => void;
    handleReset: () => void;
    mesActual: number;
    yearActual: number;
    clientes: Array<any>;
    selectedClient: any;
    estadoActual: any;
}

const Filtros: React.FC<FiltrosProps> = ({
                                             handleMonthChange,
                                             handleYearChange,
                                             handleFilterChange,
                                             handleEstadoChange,
                                             handleReset,
                                             mesActual,
                                             yearActual,
                                             clientes,
                                             selectedClient,
                                             estadoActual
                                         }) => {

    //console.log(selectedClient)

    const estados = [
        { value: '', label: 'Todos' },
        { value: 'Pendiente', label: 'Pendiente' },
        { value: 'Realizada', label: 'Realizado' },
    ];

    // @ts-ignore
    return (
        <div className="row">
            <div className="col-md-2">
                <select
                    className="form-select form-select-solid"
                    onChange={handleMonthChange}
                    value={mesActual}
                >
                    { meses.map((mes, index) => (
                        // @ts-ignore
                        <option key={index} value={mes.value}>{mes.label}</option>
                    ))}

                </select>
            </div>
            <div className="col-md-2">
                <select
                    className="form-select form-select-solid"
                    onChange={handleYearChange}
                    value={yearActual}
                >
                    { years.map((year, index) => (
                        // @ts-ignore
                        <option key={index} value={year.value}>{year.label}</option>
                    ))}

                </select>
            </div>

            <div className="col-md-2">
                <Select
                    options={clientes.map(cliente => ({
                        value: cliente.cliente_id,
                        label: `${cliente.nombre} ${cliente.apellido}`
                    }))}
                    value={selectedClient}
                    onChange={handleFilterChange}
                />
            </div>

            <div className="col-md-2">
                <select
                    className="form-select form-select-solid"
                    onChange={handleEstadoChange} // Cambiar a la función local
                    value={estadoActual}
                >
                    {estados.map((estado, index) => (
                        <option key={index} value={estado.value}>{estado.label}</option>
                    ))}
                </select>

            </div>


            <div className="col-md-2">
                <button className="btn btn-danger btn-sm" onClick={handleReset}>
                    Limpiar
                </button>
            </div>
        </div>
    );
};

export default Filtros;

