import React, { ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import {logout} from "../slice/LoginSlice";
import {Header} from "../components/Header";
import {Sidebar} from "../components/Sidebar";

type DefaultLayoutProps = {
    children: ReactNode;
};

export const PrivateLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
    return (
        <div className="d-flex flex-column flex-root app-root" id="kt_app_root" data-kt-app-layout="dark-sidebar">
            <div className="app-page flex-column flex-column-fluid" id="kt_app_page" data-kt-drawer="true" data-kt-drawer-name="app-sidebar">
                <Header/>
                <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                    <Sidebar/>
                    <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                        <div className="d-flex flex-column flex-column-fluid">
                            <div id="kt_app_content_container" className="app-container container-fluid">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
