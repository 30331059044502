// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import axios from "axios";
import { URL_BASE } from "../../config/config";

export interface IFormInput {
    factura_id: number;
    fecha_pago: string;
    monto_pagado: number;
    voucher_transferencia: string;
    estado_pago: string;
    cliente_id: number;
    monto_total: number;
    monto_abono?: number;
    month: number;
    year: number;
}

interface FormularioProps {
    onSubmit: (data: IFormInput) => void;
    errors: any;
}

const Formulario: React.FC<FormularioProps> = ({ onSubmit, errors }) => {
    const { register, setValue, watch } = useFormContext();
    const [facturas, setFacturas] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [montoTotal, setMontoTotal] = useState(0);
    const [saldoRestante, setSaldoRestante] = useState(0);
    const [isPaid, setIsPaid] = useState(false);

    const clienteId = watch("cliente_id");
    const selectedMonth = watch("month") || new Date().getMonth() + 1;
    const selectedYear = watch("year") || new Date().getFullYear();
    const facturaId = watch("factura_id");
    const montoPagado = watch("monto_pagado") || 0;

    useEffect(() => {
        const fetchClientes = async () => {
            try {
                const { data } = await axios.get(`${URL_BASE}/clientes?opcion=all`);
                setClientes(data);
            } catch (error) {
                console.error("Error fetching clientes:", error);
            }
        };

        fetchClientes();
        setValue("fecha_pago", new Date().toISOString().split("T")[0]);
        setValue("month", selectedMonth);
        setValue("year", selectedYear);
    }, [setValue]);

    const fetchFacturas = async (clientId: number, month: number, year: number) => {
        try {
            const { data } = await axios.get(`${URL_BASE}/facturas?opcion=get&filtro[cliente_id]=${clientId}&month=${month}&year=${year}`);
            setFacturas(data);
        } catch (error) {
            console.error("Error fetching invoices data:", error);
        }
    };

    useEffect(() => {
        if (clienteId) {
            fetchFacturas(clienteId, selectedMonth, selectedYear);
        }
    }, [clienteId, selectedMonth, selectedYear]);

    const handleFacturaChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const facturaId = Number(event.target.value);
        const facturaSeleccionada = facturas.find(factura => factura.factura_id === facturaId);

        if (facturaSeleccionada) {
            try {
                const { data } = await axios.get(`${URL_BASE}/pagos-factura/${facturaId}`);
                const diferencia = data.diferencia;

                setMontoTotal(facturaSeleccionada.monto_total);
                setSaldoRestante(diferencia);
                setIsPaid(diferencia === 0);

                setValue("monto_pagado", diferencia > 0 ? diferencia : 0);
            } catch (error) {
                console.error("Error fetching payment data:", error);
            }
        }
    };

    const handleMontoPagadoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value) || 0;
        setValue("monto_pagado", value);

        if (value < saldoRestante) {
            setValue("estado_pago", "abono");
        } else {
            setValue("estado_pago", "pago total");
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <div className="row">
                <div className="col-md-4 mb-10">
                    <label className="form-label">Cliente</label>
                    <select
                        className="form-select form-select-solid"
                        {...register("cliente_id", { required: true })}
                    >
                        <option value="">Seleccione un cliente</option>
                        {clientes.map(cliente => (
                            <option key={cliente.cliente_id} value={cliente.cliente_id}>
                                {cliente.nombre + " " + cliente.apellido}
                            </option>
                        ))}
                    </select>
                    {errors.cliente_id && <span className="text-danger">Este campo es obligatorio</span>}
                </div>

                <div className="col-md-4 mb-10">
                    <label className="form-label">Mes</label>
                    <select
                        className="form-select form-select-solid"
                        {...register("month")}
                    >
                        {[...Array(12)].map((_, index) => (
                            <option key={index + 1} value={index + 1}>
                                {new Date(0, index).toLocaleString("default", { month: "long" })}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="col-md-4 mb-10">
                    <label className="form-label">Año</label>
                    <select
                        className="form-select form-select-solid"
                        {...register("year")}
                    >
                        {[...Array(5)].map((_, index) => (
                            <option key={index} value={selectedYear - index}>
                                {selectedYear - index}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 mb-10">
                    <label className="form-label">Nº de Factura</label>
                    <select
                        className="form-select form-select-solid"
                        {...register("factura_id", { required: true })}
                        onChange={handleFacturaChange}
                    >
                        <option value="">Seleccione una factura</option>
                        {facturas.map(factura => (
                            <option key={factura.factura_id} value={factura.factura_id}>
                                {factura.factura_id} - {factura.monto_total}
                            </option>
                        ))}
                    </select>
                    {errors.factura_id && <span className="text-danger">Este campo es obligatorio</span>}
                </div>

                <div className="col-md-6 mb-10">
                    <label className="form-label">Voucher de Transferencia</label>
                    <input
                        type="text"
                        className="form-control form-control-solid"
                        {...register("voucher_transferencia", { required: true })}
                    />
                    {errors.voucher_transferencia && <span className="text-danger">Este campo es obligatorio</span>}
                </div>
            </div>

            <div className="row">
                <div className="col-md-4 mb-10">
                    <label className="form-label">Monto Total de la Factura</label>
                    <input
                        type="number"
                        className="form-control form-control-solid"
                        value={montoTotal}
                        disabled
                    />
                </div>

                <div className="col-md-4 mb-10">
                    <label className="form-label">Saldo Restante</label>
                    <input
                        type="number"
                        className="form-control form-control-solid"
                        value={saldoRestante}
                        disabled
                    />
                </div>

                <div className="col-md-4 mb-10">
                    <label className="form-label">Monto a Pagar</label>
                    <input
                        type="number"
                        className="form-control form-control-solid"
                        {...register("monto_pagado", { required: true })}
                        onChange={handleMontoPagadoChange}
                    />
                    {errors.monto_pagado && <span className="text-danger">Este campo es obligatorio</span>}
                </div>
            </div>

            <div className="d-flex justify-content-end">
                <button type="submit" className="btn btn-primary" disabled={isPaid}>
                    Generar
                </button>
            </div>
        </form>
    );
};

export default Formulario;





