//@ts-nocheck
import { Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import { PrivateLayout } from "../layouts/PrivateLayout";
import { useSelector } from "react-redux";
import { URL_BASE } from "../config/config";
import { FaUserSlash, FaUserCheck, FaPlug, FaChartBar } from "react-icons/fa"; // Import icons

export const Dashboard = () => {
    const user = useSelector((state: any) => state.login.user);
    const [indicators, setIndicators] = useState({
        totalClientesCorte: 0,
        totalClientesSinCorte: 0,
        nuevosEmpalmesMes: 0,
        consumoPromedio: 0
    });

    useEffect(() => {
        const fetchData = async () => {
            const [clientesCorte, clientesSinCorte, nuevosEmpalmes, consumoProm] = await Promise.all([
                axios.get(`${URL_BASE}/reportes/clientes-corte`),
                axios.get(`${URL_BASE}/reportes/clientes-sin-corte`),
                axios.get(`${URL_BASE}/reportes/nuevos-empalmes-mes`),
                axios.get(`${URL_BASE}/reportes/consumo-promedio`)
            ]);

            setIndicators({
                totalClientesCorte: clientesCorte.data.data.total_clientes_corte,
                totalClientesSinCorte: clientesSinCorte.data.data.total_clientes_sin_corte,
                nuevosEmpalmesMes: nuevosEmpalmes.data.data.nuevos_empalmes_mes,
                consumoPromedio: parseFloat(consumoProm.data.data.consumo_promedio_kwh) || 0
            });
        };

        fetchData();
    }, []);

    return (
        <PrivateLayout>
            <div className="container mt-5">
                <Card className="mb-4">
                    <div className="py-5 px-5 text-center">
                        <h1>Bienvenido, {user.name}</h1>
                    </div>
                </Card>

                <div className="row mt-4">
                    <div className="col-md-3">
                        <Card className="shadow-sm text-center bg-danger text-white">
                            <div className="card-body">
                                <FaUserSlash size={40} className="mb-3" />
                                <h5>Total Clientes con Corte</h5>
                                <p className="display-6">{indicators.totalClientesCorte}</p>
                            </div>
                        </Card>
                    </div>
                    <div className="col-md-3">
                        <Card className="shadow-sm text-center bg-success text-white">
                            <div className="card-body">
                                <FaUserCheck size={40} className="mb-3" />
                                <h5>Total Clientes sin Corte</h5>
                                <p className="display-6">{indicators.totalClientesSinCorte}</p>
                            </div>
                        </Card>
                    </div>
                    <div className="col-md-3">
                        <Card className="shadow-sm text-center bg-primary text-white">
                            <div className="card-body">
                                <FaPlug size={40} className="mb-3" />
                                <h5>Nuevos Empalmes del Mes</h5>
                                <p className="display-6">{indicators.nuevosEmpalmesMes}</p>
                            </div>
                        </Card>
                    </div>
                    <div className="col-md-3">
                        <Card className="shadow-sm text-center bg-info text-white">
                            <div className="card-body">
                                <FaChartBar size={40} className="mb-3" />
                                <h5>Consumo Promedio (kWh)</h5>
                                <p className="display-6">{indicators.consumoPromedio.toFixed(2)}</p>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </PrivateLayout>
    );
};





