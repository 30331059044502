import React from "react";
import Select from 'react-select';

interface FiltrosClientesProps {
    clientes: Array<any>;
    selectedClient: any;
    onFilterChange: (client: any) => void;
    onReset: () => void;
}

const FiltrosClientes: React.FC<FiltrosClientesProps> = ({ clientes, selectedClient, onFilterChange, onReset }) => {
    return (
        <div className="row mb-3">
            <div className="col-md-3">
                <Select
                    options={clientes.map(cliente => ({
                        value: cliente.cliente_id,
                        label: `${cliente.nombre} ${cliente.apellido}`
                    }))}
                    value={selectedClient ? { value: selectedClient, label: selectedClient.label } : null}
                    onChange={(option) => onFilterChange(option)}
                    placeholder="Seleccionar Cliente"
                    isClearable
                />
            </div>
            <div className="col-md-3">
                <button
                    className="btn btn-danger btn-sm"
                    onClick={onReset}
                >
                    Limpiar
                </button>
            </div>
        </div>
    );
};

export default FiltrosClientes;


