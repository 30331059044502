import React, {useEffect, useState} from 'react';
import {Button, Form, Container, Row, Col} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {loginUser, logout} from "../slice/LoginSlice";
import {useNavigate} from 'react-router-dom';
import {RootState} from "../store/store";
import {DefaultLayout} from "../layouts/DefaultLayout";
import Swal from "sweetalert2";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const token = useSelector((state: RootState) => state.login.token);
    const isLoading = useSelector((state: RootState) => state.login.isLoading);
    const error = useSelector((state: RootState) => state.login.error);
    const user = useSelector((state: RootState) => state.login.user);

    const handleLogin = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        // @ts-ignore
        const actionResult = await dispatch(loginUser({email, password}));
        const loginResult = actionResult.payload;
        if (loginResult && loginResult.token) {
            Swal.fire({
                title: '¡Éxito!',
                text: 'Has iniciado sesión correctamente.',
                icon: 'success',
                confirmButtonText: 'Aceptar'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/dashboard');
                }
            });
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'Ha ocurrido un error al iniciar sesión.',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    const handleLogout = () => {
        dispatch(logout());
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        // Any necessary side effects based on token
    }, [token]);

    return (
        <DefaultLayout>
            <h2 className="text-center mb-4">Iniciar Sesión</h2>
            <form className="form w-100" id="kt_sign_in_form" onSubmit={handleLogin}>
                <div className="fv-row mb-8">
                    <input
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        placeholder="Email"
                        className="form-control bg-transparent"
                    />
                </div>
                <div className="fv-row mb-8 position-relative">
                    <input
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        placeholder="Password"
                        className="form-control bg-transparent"
                    />
                    <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                        onClick={togglePasswordVisibility}
                        className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                    />
                </div>
                <div className="d-grid mb-10">
                    <button type="submit" id="kt_sign_in_submit" className="btn btn-primary">
                        {isLoading ? (
                            <>
                                <span className="spinner-border spinner-border-sm align-middle me-2"></span>
                                Espere...
                            </>
                        ) : (
                            <span className="indicator-label">
                                Ingresar
                            </span>
                        )}
                    </button>

                </div>
            </form>
        </DefaultLayout>
    );
}

export default Login;


