declare global {
    interface Window {
        KTComponents: any;
    }
}

export const initializeSidebar = () => {
    // Wait for DOM content to be loaded
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', setupSidebar);
    } else {
        setupSidebar();
    }
};

const setupSidebar = () => {
    // Get sidebar elements
    const sidebarElement = document.querySelector('#kt_app_sidebar');
    const sidebarToggleElement = document.querySelector('#kt_app_sidebar_toggle');
    
    if (!sidebarElement || !sidebarToggleElement) {
        console.warn('Sidebar elements not found');
        return;
    }

    // Initialize sidebar toggle
    if (typeof window.KTComponents !== 'undefined') {
        window.KTComponents.init();
    }

    // Add click event listener for manual toggle
    sidebarToggleElement.addEventListener('click', () => {
        document.body.classList.toggle('app-sidebar-minimize');
        
        // Store the state in localStorage
        const isMinimized = document.body.classList.contains('app-sidebar-minimize');
        localStorage.setItem('sidebar_minimize_state', isMinimized ? 'on' : 'off');
    });

    // Restore sidebar state from localStorage
    const savedState = localStorage.getItem('sidebar_minimize_state');
    if (savedState === 'on') {
        document.body.classList.add('app-sidebar-minimize');
    }
};
