// @ts-nocheck
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { DataTable } from "../../components/ui/DataTable";
import { columnsFacturas } from "../../data/Datos";
import {URL_BASE} from "../../config/config";
import {Pagination} from "../../components/ui/Pagination";
interface TableProps {
    facturas: Array<any>;
    paginate: any;
    handleChangePage: (page: number) => void;
}



const Table: React.FC<TableProps> = ({ facturas , paginate ,handleChangePage}) => {
    const handleDownloadPdf = async (id: number) => {
        try {
            const response = await fetch(`${URL_BASE}/facturas/${id}/pdf`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                },
            });
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `factura_${id}.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    const handleSendWhatsapp = (id: number) => {
        // Lógica para enviar por WhatsApp
    };

    return (
        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
                {facturas?.length ? (
                    <DataTable
                        items={facturas}
                        columns={columnsFacturas}
                        idKey={'factura_id'}
                        color={{
                            'pagado': 'badge-success',
                            'abono': 'badge-primary',
                            'vencido': 'badge-danger',
                            'pendiente': 'badge-warning'
                        }}
                        isActions={true}
                        renderActions={(id) => (
                            <div className='btn-group'>
                                <button
                                    className="btn btn-sm btn-danger"
                                    onClick={() => handleDownloadPdf(id)}
                                >
                                    <FontAwesomeIcon icon={faFilePdf}/>
                                </button>
                                {/*  <button
                                    className="btn btn-sm btn-success"
                                    onClick={() => handleSendWhatsapp(id)}
                                >
                                    <FontAwesomeIcon icon={faWhatsapp} />
                                </button>*/}
                            </div>
                        )}
                    />
                ) : (
                    <p style={{marginTop: '30px', fontSize: '16px'}}>No hay facturas disponibles para este mes y
                        año.</p>
                )}
            </div>
            <div className="row">
                {paginate ? (
                    <Pagination
                        links={paginate}
                        onChange={(page) => handleChangePage(page)}
                    />
                ) : null}
            </div>

        </div>
    );
};

export default Table;
