export const meses = [
    { "value": 1, "label": "Enero" },
    { "value": 2, "label": "Febrero" },
    { "value": 3, "label": "Marzo" },
    { "value": 4, "label": "Abril" },
    { "value": 5, "label": "Mayo" },
    { "value": 6, "label": "Junio" },
    { "value": 7, "label": "Julio" },
    { "value": 8, "label": "Agosto" },
    { "value": 9, "label": "Septiembre" },
    { "value": 10, "label": "Octubre" },
    { "value": 11, "label": "Noviembre" },
    { "value": 12, "label": "Diciembre" }
]

export const years = [
    { "value": 2024, "label": "2024" },
    { "value": 2025, "label": "2025" },
    { "value": 2026, "label": "2026" },
    { "value": 2027, "label": "2027" },
    { "value": 2028, "label": "2028" },
    { "value": 2029, "label": "2029" },
    { "value": 2030, "label": "2030" },
    { "value": 2031, "label": "2031" },
]