import React, { useState, FunctionComponent, ReactNode } from 'react';

type Column = {
    id: number;
    label: string;
    key?: string;
    isImage?: boolean;
    urlImage?: string;
    shouldHaveBadge?: boolean;
    badge?: boolean;
    isVisible?: boolean;
};

type Item = {
    id: string | number;
    [key: string]: any; // additional properties of Item
};

type ColorType = {
    [key: string]: string;
};

interface Props {
    columns: Column[];
    items?: Item[]; // Made 'items' optional
    isCheck?: boolean;
    idKey?: string;
    isActions?: boolean;
    renderActions?: (id: string | number) => ReactNode; // render prop for actions
    color?: ColorType;
    loading?: boolean;
    onSortChange?: (columnKey: string, direction: string) => void; // optional function
    visibleColumns?: { [key: number]: boolean }; // keys are numbers (column ids)
    renderBulkActions?: (selectedItems: (string | number)[]) => ReactNode; // Added for bulk actions
}

export const DataTable: FunctionComponent<Props> = ({
                                                        columns,
                                                        items,
                                                        isCheck = false,
                                                        idKey = 'id',
                                                        isActions = false,
                                                        renderActions,
                                                        color = {},
                                                        loading = false,
                                                        onSortChange,
                                                        visibleColumns = {},
                                                        renderBulkActions,
                                                    }) => {
    const safeItems = items ?? []; // Ensure 'safeItems' is always an array
    const [sortAsc, setSortAsc] = useState(true);
    const [sortedColumn, setSortedColumn] = useState<string | undefined>();

    const [selectedItems, setSelectedItems] = useState<(string | number)[]>([]);

    const getNestedValue = (obj: any, path?: string) => {
        if (!path) return 'Sin registro';
        const value = path.split('.').reduce((obj: any, key: string) => {
            if (obj && obj[key] !== undefined && obj[key] !== null) {
                return obj[key];
            }
            return undefined;
        }, obj);

        if (value === undefined) {
            return 'Sin registro';
        }

        if (typeof value === 'object') {
            // Handle arrays and objects
            return JSON.stringify(value);
        }

        return value;
    };

    const getColorType = (tipo: string) => {
        if (!color.hasOwnProperty(tipo)) {
            throw new Error(`Tipo ${tipo} no es válido`);
        }

        const colorClass = color[tipo];
        return <span className={`badge ${colorClass}`}>{tipo}</span>;
    };

    const handleSort = (columnKey?: string) => {
        if (!columnKey) return; // Exit if columnKey is undefined
        const newSortAsc = sortedColumn === columnKey ? !sortAsc : true;
        setSortAsc(newSortAsc);
        setSortedColumn(columnKey);
        // Calls the data reload function provided via props
        onSortChange && onSortChange(columnKey, newSortAsc ? 'asc' : 'desc');
    };

    const isColumnVisible = (columnId: number): boolean => {
        return visibleColumns[columnId] ?? true;
    };

    const renderImageCell = (value: any, urlImage?: string) => {
        let imageUrl = '';

        if (Array.isArray(value)) {
            if (value.length > 0) {
                const firstImage = value[0];
                if (typeof firstImage === 'string') {
                    imageUrl = firstImage;
                } else if (typeof firstImage === 'object' && firstImage.image_path) {
                    imageUrl = firstImage.image_path;
                }
            }
        } else if (typeof value === 'object' && value.image_path) {
            imageUrl = value.image_path;
        } else if (typeof value === 'string') {
            imageUrl = value;
        }

        if (imageUrl) {
            const fullImageUrl = urlImage ? `${urlImage}${imageUrl}` : imageUrl;
            return (
                <img
                    src={fullImageUrl}
                    alt="Imagen"
                    style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                />
            );
        } else {
            return 'Sin imagen';
        }
    };

    // Handle selection of all items
    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            const allItemIds = safeItems.map((item) => item[idKey]);
            setSelectedItems(allItemIds);
        } else {
            setSelectedItems([]);
        }
    };

    // Handle selection of individual items
    const handleSelectItem = (e: React.ChangeEvent<HTMLInputElement>, itemId: string | number) => {
        if (e.target.checked) {
            setSelectedItems((prevSelected) => [...prevSelected, itemId]);
        } else {
            setSelectedItems((prevSelected) => prevSelected.filter((id) => id !== itemId));
        }
    };

    return (
        <div className="py-5">
            {/* Render bulk actions if any items are selected */}
            {selectedItems.length > 0 && renderBulkActions && (
                <div className="bulk-actions">{renderBulkActions(selectedItems)}</div>
            )}
            <table
                className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                id="kt_customers_table"
            >
                <thead>
                <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                    {isCheck && (
                        <th className="w-10px pe-2">
                            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={
                                        safeItems.length > 0 &&
                                        selectedItems.length === safeItems.length
                                    }
                                    onChange={handleSelectAll}
                                />
                            </div>
                        </th>
                    )}
                    {columns.map(
                        (column) =>
                            isColumnVisible(column.id) && (
                                <th
                                    key={column.key || column.id}
                                    onClick={() => handleSort(column.key)}
                                    className={
                                        sortAsc && sortedColumn === column.key
                                            ? 'table-sort-asc'
                                            : 'table-sort-desc'
                                    }
                                >
                                    {column.label}
                                </th>
                            )
                    )}
                    {isActions && <th className="text-center">Acciones</th>}
                </tr>
                </thead>
                <tbody className="fw-semibold text-gray-600">
                {/* Handle loading state */}
                {loading && (
                    <tr>
                        <td colSpan={100} className="text-center">
                            Cargando...
                        </td>
                    </tr>
                )}
                {/* Handle empty data */}
                {!loading && safeItems.length === 0 && (
                    <tr>
                        <td colSpan={100} className="text-center">
                            No hay registros
                        </td>
                    </tr>
                )}
                {/* Render table rows */}
                {!loading &&
                    safeItems.map((item) => (
                        <tr className="odd" key={item[idKey]}>
                            {isCheck && (
                                <td>
                                    <div className="form-check form-check-sm form-check-custom form-check-solid">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={selectedItems.includes(item[idKey])}
                                            onChange={(e) => handleSelectItem(e, item[idKey])}
                                        />
                                    </div>
                                </td>
                            )}

                            {columns.map(
                                (column) =>
                                    isColumnVisible(column.id) && (
                                        <td key={column.key || column.id}>
                                            {(() => {
                                                const cellValue = getNestedValue(
                                                    item,
                                                    column.key
                                                );

                                                if (column.isImage) {
                                                    return renderImageCell(
                                                        cellValue,
                                                        column.urlImage
                                                    );
                                                } else if (column.badge) {
                                                    return getColorType(cellValue);
                                                } else if (typeof cellValue === 'object') {
                                                    // Customize how to display objects or arrays
                                                    return JSON.stringify(cellValue);
                                                } else {
                                                    return cellValue;
                                                }
                                            })()}
                                        </td>
                                    )
                            )}

                            {isActions && renderActions && (
                                <td className="text-center">
                                    {renderActions(item[idKey])}
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};






