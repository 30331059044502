// src/components/clientes/ImportModalClientes.tsx

import React from "react";

interface ImportModalProps {
    show: boolean;
    handleClose: () => void;
    onSubmit: (e: React.FormEvent) => void;
}

const ImportModalClientes: React.FC<ImportModalProps> = ({ show, handleClose, onSubmit }) => {
    if (!show) return null;

    return (
        <div className="modal show d-block" tabIndex={-1} role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Importar Datos</h5>
                        <button type="button" className="close" onClick={handleClose}>
                            <span>&times;</span>
                        </button>
                    </div>
                    <form onSubmit={onSubmit}>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label className="form-label">Archivo Excel</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    name="file"
                                    accept=".xlsx, .xls"
                                    required
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={handleClose}>Cerrar</button>
                            <button type="submit" className="btn btn-primary">Importar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ImportModalClientes;
