// @ts-nocheck
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { DataTable } from "../../components/ui/DataTable";
import { columnsmonthlyConsumption } from "../../data/Datos";
import {Pagination} from "../../components/ui/Pagination";

interface TableProps {
    consumos: Array<any>;
    newConsumo: (id: string | number) => void;
    paginate: any;
    handleChangePage: (page: number) => void;
}

const Table: React.FC<TableProps> = ({ consumos, newConsumo ,paginate, handleChangePage}) => {
    //console.log(paginate);
    return (
        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
                {consumos?.length ? (
                    <DataTable
                        items={consumos}
                        columns={columnsmonthlyConsumption}
                        idKey={'lectura_id'}
                        isActions={true}
                        color={{ 'Pendiente': 'badge-warning', 'Realizada': 'badge-success','Sin registro': 'badge-secondary', }}
                        renderActions={(id) => (
                            <div className='btn-group'>
                                <button
                                    className="btn btn-sm btn-primary"
                                    onClick={() => newConsumo(Number(id))} // Convertir a número
                                >
                                    <FontAwesomeIcon icon={faEdit}/>
                                </button>
                            </div>
                        )}
                    />
                ) : (
                    <p style={{marginTop: '30px', fontSize: '16px'}}>No hay consumos disponibles para este mes y
                        año.</p>
                )}
            </div>
            <div className="row">
                {paginate ? (
                    <Pagination
                        links={paginate}
                        onChange={(page) => handleChangePage(page)}
                    />
                ) : null}
            </div>

        </div>
    );
};

export default Table;
