// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler, useFieldArray, FormProvider } from "react-hook-form";
import axios from "axios";
import { URL_BASE } from "../../config/config";
import { Card } from "../../components/ui/Card";
import { Modal } from "../../components/ui/Modal";
import Swal from "sweetalert2";
import { PrivateLayout } from "../../layouts/PrivateLayout";
import { columnsClientes } from "../../data/Datos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faTrash,
    faEdit,
    faFilter,
    faUpload
} from "@fortawesome/free-solid-svg-icons";
import ToolActionsClientes from "./ToolActionsClientes";
import FiltrosClientes from "./FiltrosClientes";
import ClientesTable from "./ClientesTable";
import ClienteForm from "./ClienteForm";
import ImportModalClientes from "./ImportModalClientes";
import {Pagination} from "../../components/ui/Pagination";

interface IFormInput {
    id: string;
    cliente_id?: number;
    nombre: string;
    apellido: string;
    rut: string;
    email: string;
    telefono: string;
    telefono_dos?: string;
    direcciones: {
        direccion_id?: number;
        calle: string;
        ciudad: string;
        estado: string;
        codigo_postal: string;
        numero_cliente: string;
        numero_medidor: string;
    }[];
}

export const Clientes = () => {
    const methods = useForm<IFormInput>({
        defaultValues: {
            direcciones: [{ calle: "", ciudad: "", estado: "", codigo_postal: "", numero_cliente: "", numero_medidor: "" }]
        }
    });
    const { reset, setValue, handleSubmit, formState: { errors }, clearErrors, control } = methods;
    const { fields, append, remove } = useFieldArray({
        control,
        name: "direcciones"
    });

    const [clientes, setClientes] = useState<any[]>([]);
    const [houses, setHouses] = useState<any[]>([]);
    const [isModalShow, setIsModalShow] = useState(false);
    const [isImportModalShow, setIsImportModalShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [titulo, setTitulo] = useState('');
    const [paginate, setPaginate] = useState([]);
    const [params, setParams] = useState({
        opciones: 'listPage',
        orderPor: 'cliente_id',
        order: 'asc',
        perpage: 10,
        filters: {},
        page: 1
    });
    const [showFilters, setShowFilters] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);

    // Fetch data using a custom hook or directly using axios
    useEffect(() => {
        fetchData(params.page);
    }, [params]);

    useEffect(() => {
        // Obtener lista completa de clientes para el filtro
        axios.get(`${URL_BASE}/clientes`)
            .then(response => {
                setClientes(response.data);
            })
            .catch(error => {
                console.error("Error fetching clientes:", error);
            });
    }, []);

    // Ajuste en `fetchData` para utilizar los filtros de `params` al construir la URL de la API
    const fetchData = async (page: number) => {
        try {
            // Si `params.filters.cliente_id` está definido, agrégalo a la URL
            const filterParam = params.filters.cliente_id ? `&filtro[cliente_id]=${params.filters.cliente_id}` : '';
            const { data, status } = await axios.get(`${URL_BASE}/clientes?opcion=paginate&page=${page}${filterParam}`);

            if (status === 200) {
                setHouses(data.data);
                setPaginate(data.links);
            }
        } catch (error) {
            console.error("Error fetching clientes data:", error);
        }
    }

    const deleteCliente = (id: number) => {
        Swal.fire({
            title: '¿Estás seguro de eliminar este cliente?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0d6efd',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Sí, eliminar!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { status } = await axios.delete(`${URL_BASE}/clientes/${id}`);
                    if (status === 200) {
                        Swal.fire(
                            'Eliminado!',
                            'El cliente ha sido eliminado.',
                            'success'
                        );
                        fetchData(params.page);
                    }
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Error al eliminar el cliente!',
                    });
                }
            }
        });
    };

    const openFormModal = (id = 0) => {
        if (id > 0) {
            setIsEdit(true);
            const clienteEdit = houses.find((cliente: any) => cliente.cliente_id === id);
            if (clienteEdit) {
                setValue('cliente_id', clienteEdit.cliente_id);
                setValue('nombre', clienteEdit.nombre);
                setValue('apellido', clienteEdit.apellido);
                setValue('rut', clienteEdit.rut);
                setValue('email', clienteEdit.email);
                setValue('telefono', clienteEdit.telefono);
                setValue('telefono_dos', clienteEdit.telefono_dos);
                reset({
                    cliente_id: clienteEdit.cliente_id,
                    nombre: clienteEdit.nombre,
                    apellido: clienteEdit.apellido,
                    rut: clienteEdit.rut,
                    email: clienteEdit.email,
                    telefono: clienteEdit.telefono,
                    telefono_dos: clienteEdit.telefono_dos,
                    direcciones: clienteEdit.direcciones.map((direccion: any) => ({
                        calle: direccion.calle,
                        ciudad: direccion.ciudad,
                        estado: direccion.estado,
                        codigo_postal: direccion.codigo_postal,
                        numero_cliente: direccion.numero_cliente,
                        numero_medidor: direccion.numero_medidor,
                        direccion_id: direccion.direccion_id
                    }))
                });
            }
        } else {
            setIsEdit(false);
            reset({
                nombre: '',
                apellido: '',
                rut: '',
                email: '',
                telefono: '',
                telefono_dos: '',
                direcciones: [{ calle: "", ciudad: "", estado: "", codigo_postal: "", numero_cliente: "", numero_medidor: "" }]
            });
        }

        setTitulo(id > 0 ? 'Editar Cliente' : 'Nuevo Cliente');
        setIsModalShow(true);
    };

    const closeFormModal = () => {
        reset();
        clearErrors();
        setIsEdit(false);
        setIsModalShow(false);
    };

    const onSubmit: SubmitHandler<IFormInput> = async (data) => {
        try {
            const payload = {
                nombre: data.nombre,
                apellido: data.apellido,
                rut: data.rut,
                email: data.email,
                telefono: data.telefono,
                telefono_dos: data.telefono_dos,
                direcciones: data.direcciones
            };

            Swal.fire({
                title: 'Esperando...',
                text: 'Guardando el cliente!',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            const { status } = await axios({
                method: isEdit ? 'put' : 'post',
                url: `${URL_BASE}/clientes${isEdit ? '/' + data.cliente_id : ''}`,
                data: payload,
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            Swal.close();

            if (status === 200) {
                Swal.fire(
                    'Guardado!',
                    'El cliente ha sido guardado.',
                    'success'
                );
                reset();
                closeFormModal();
                fetchData(params.page);
            }
        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrió un error al guardar el cliente!',
            });
        }
    };

    const openImportModal = () => {
        setIsImportModalShow(true);
    };

    const closeImportModal = () => {
        setIsImportModalShow(false);
    };

    const handleImportSubmit = async (event: any) => {
        event.preventDefault();
        const file = event.target.file.files[0];

        const formData = new FormData();
        formData.append('file', file);

        try {
            Swal.fire({
                title: 'Esperando...',
                text: 'Importando datos!',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            const { status } = await axios.post(`${URL_BASE}/importClients`, formData);

            Swal.close();

            if (status === 200) {
                Swal.fire(
                    'Importado!',
                    'Los datos han sido importados.',
                    'success'
                );
                closeImportModal();
                fetchData(params.page);
            }

        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrió un error al importar los datos!',
            });
        }
    };

    const handleChangePage = async (page: number) => {
        setParams(prev => ({ ...prev, page }));
        fetchData(page);
    };

    // Ajustes en `handleFilterChange` y `fetchData` para que el filtro funcione correctamente

    const handleFilterChange = (selectedOption: any) => {
        setSelectedClient(selectedOption);

        // Solo agrega `cliente_id` si `selectedOption` tiene un valor
        const filters = {
            ...params,
            filters: {
                ...params.filters,
                cliente_id: selectedOption?.value || '' // Se asegura de limpiar si es `null`
            },
            page: 1,
        };

        setParams(filters);
    }




    const resetFiltro = async () => {
        setSelectedClient(null);
        setParams(prev => ({ ...prev, filters: {}, page: 1 }));
        fetchData(1);
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    return (
        <PrivateLayout>
            <div className="container mt-5">
                <Card
                    title="Clientes"
                    toolActions={() => (
                        <ToolActionsClientes
                            onNew={() => openFormModal()}
                            onImport={openImportModal}
                            onToggleFilters={toggleFilters}
                        />
                    )}
                >
                    {showFilters && (
                        <FiltrosClientes
                            clientes={clientes}
                            selectedClient={selectedClient}
                            onFilterChange={handleFilterChange}
                            onReset={resetFiltro}
                        />
                    )}
                    <ClientesTable
                        clientes={houses}
                        columns={columnsClientes}
                        onEdit={openFormModal}
                        onDelete={deleteCliente}
                        onSortChange={async (columnKey, direction) => {
                            const url = `${URL_BASE}/clientes?opcion=paginate&orderPor=${columnKey}&order=${direction}`;
                            try {
                                const { data, status } = await axios.get(url);
                                if (status === 200) {
                                    setHouses(data.data);
                                    setPaginate(data.links);
                                }
                            } catch (error) {
                                console.error("Error sorting clientes:", error);
                            }
                        }}
                    />
                    <Pagination
                        links={paginate}
                        onChange={handleChangePage}
                    />
                </Card>
            </div>

            {/* Modal para Crear/Editar Cliente */}
            <Modal
                show={isModalShow}
                handleClose={closeFormModal}
                title={titulo}
            >
                <FormProvider {...methods}>
                    <ClienteForm
                        onSubmit={handleSubmit(onSubmit)}
                        errors={errors}
                        fields={fields}
                        append={append}
                        remove={remove}
                        isEdit={isEdit}
                    />
                </FormProvider>
            </Modal>

            {/* Modal para Importar _Clientes */}
            <ImportModalClientes
                show={isImportModalShow}
                handleClose={closeImportModal}
                onSubmit={handleImportSubmit}
            />
        </PrivateLayout>
    );

};


