// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import axios from "axios";
import { URL_BASE } from "../../config/config";
import { Card } from "../../components/ui/Card";
import { Modal } from "../../components/ui/Modal";
import Swal from "sweetalert2";
import { PrivateLayout } from "../../layouts/PrivateLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFilter, faDownload } from "@fortawesome/free-solid-svg-icons";
import { IFormInput } from "./Formulario";
import Formulario from "./Formulario";
import Table from "./Table";
import Filtros from "./Filtros";
//import ResumenMes from "./ResumenMes";

export const Pagos = () => {
    const methods = useForm<IFormInput>();
    const { reset, setValue, handleSubmit, formState: { errors } } = methods;
    const [pagos, setPagos] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [isModalShow, setIsModalShow] = useState(false);
    const [titulo, setTitulo] = useState('');
    const [mesActual, setMesActual] = useState(new Date().getMonth() + 1);
    const [yearActual, setYearActual] = useState(new Date().getFullYear());
    const [showFilters, setShowFilters] = useState(false);
    const [resumen, setResumen] = useState({ recaudado: 0, pagadas: 0, vencidas: 0, pendiente: 0 });

    useEffect(() => {
        getClientes();
        fetchData(mesActual, yearActual, null);
    }, []);

    useEffect(() => {
        fetchResumen(mesActual, yearActual, selectedClient?.value);
    }, [mesActual, yearActual, selectedClient]);

    const fetchData = async (month: number, year: number, clientId: number | null) => {
        try {
            const { data } = await axios.get(`${URL_BASE}/pagos?opcion=paginate&month=${month}&year=${year}${clientId ? `&filtro[cliente_id]=${clientId}` : ''}`);
            setPagos(data.data);
        } catch (error) {
            console.error("Error fetching payments data:", error);
        }
    }

    const fetchResumen = async (month: number, year: number, clientId: number | null) => {
        try {
            const { data } = await axios.get(`${URL_BASE}/pagos?opcion=get&month=${month}&year=${year}${clientId ? `&filtro[cliente_id]=${clientId}` : ''}`);

            const totalRecaudado = data.reduce((sum, pago) => sum + (pago.estado_pago === 'pagado' ? pago.monto_pagado : 0), 0);
            const totalPagadas = data.filter(pago => pago.estado_pago === 'pagado').length;
            const totalVencidas = data.filter(pago => pago.estado_pago === 'vencido').length;
            const totalPendiente = data.reduce((sum, pago) => sum + (pago.estado_pago === 'pendiente' ? pago.monto_pagado : 0), 0);
            setResumen({ recaudado: totalRecaudado, pagadas: totalPagadas, vencidas: totalVencidas, pendiente: totalPendiente });
        } catch (error) {
            console.error("Error fetching resumen data:", error);
        }
    }

    const getClientes = async () => {
        try {
            const { data } = await axios.get(`${URL_BASE}/clientes?opcion=get`);
            setClientes(data);
        } catch (error) {
            console.error("Error fetching clients:", error);
        }
    }

    const handleMonthChange = (event: { target: { value: string; }; }) => {
        const month = parseInt(event.target.value);
        setMesActual(month);
        fetchData(month, yearActual, selectedClient?.value);
        fetchResumen(month, yearActual, selectedClient?.value);
    }

    const handleYearChange = (event: { target: { value: string; }; }) => {
        const year = parseInt(event.target.value);
        setYearActual(year);
        fetchData(mesActual, year, selectedClient?.value);
        fetchResumen(mesActual, year, selectedClient?.value);
    }

    const handleFilterChange = (client: any) => {
        setSelectedClient(client);
        fetchData(mesActual, yearActual, client?.value);
        fetchResumen(mesActual, yearActual, client?.value);
    }

    const handleReset = () => {
        setSelectedClient(null);
        fetchData(mesActual, yearActual, null);
        fetchResumen(mesActual, yearActual, null);
    }

    const newPago = () => {
        reset();
        setTitulo('Crear Pago');
        setIsModalShow(true);
    }

    const closeModal = () => {
        reset();
        setIsModalShow(false);
    }

    const onSubmit: SubmitHandler<IFormInput> = async (data) => {
        try {

            //console.log(data);
            const yearActual = new Date().getFullYear();
            setYearActual(yearActual);
            Swal.fire({
                title: 'Esperando...',
                text: 'Generando pago!',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            const response = await axios.post(`${URL_BASE}/pagos`, data);

            Swal.close();

            if (response.status === 201 || response.status === 200) {
                Swal.fire(
                    'Generado!',
                    `Pago del mes ${data.mes} y año ${data.year} generado.`,
                    'success'
                );
                reset();
                closeModal();
                const mesActual = new Date().getMonth() + 1;
                setMesActual(mesActual);
                fetchData(mesActual, yearActual , selectedClient?.value);
                fetchResumen(data.mes, data.year, selectedClient?.value);
            }
        } catch (error) {
            Swal.close();
            console.error("Error details:", error);

            let errorMessage = 'Algo salió mal!';

            // Verifica si el error tiene una respuesta de la API y un mensaje de error
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: errorMessage,
            });
        }
    };


    return (
        <PrivateLayout>
            <div className="container mt-5">
                <Card
                    title="Pagos"
                    toolActions={() => (
                        <div className="btn-group">
                            <button onClick={newPago} className="btn btn-warning btn-sm">
                                <FontAwesomeIcon icon={faPlus} /> Crear Pago
                            </button>
                            <button onClick={() => setShowFilters(!showFilters)} className="btn btn-info btn-sm">
                                <FontAwesomeIcon icon={faFilter} /> Filtrar
                            </button>
                        </div>
                    )}
                >
                    {/*<ResumenMes
                        resumen={resumen}
                    />*/}
                    {showFilters && (
                        <Filtros
                            handleMonthChange={handleMonthChange}
                            handleYearChange={handleYearChange}
                            handleFilterChange={handleFilterChange}
                            handleReset={handleReset}
                            mesActual={mesActual}
                            yearActual={yearActual}
                            clientes={clientes}
                            selectedClient={selectedClient}
                        />
                    )}

                    <Table
                        pagos={pagos}
                    />
                </Card>
            </div>

            <Modal show={isModalShow} handleClose={closeModal} title={titulo}>
                <FormProvider {...methods}>
                    <Formulario
                        onSubmit={handleSubmit(onSubmit)}
                        errors={errors}
                    />
                </FormProvider>
            </Modal>
        </PrivateLayout>
    );
}

export default Pagos;
