// @ts-nocheck
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { DataTable } from "../../components/ui/DataTable";


interface ClientesTableProps {
    clientes: Array<any>;
    columns: any;
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
    onSortChange: (columnKey: string, direction: string) => void;
}

const ClientesTable: React.FC<ClientesTableProps> = ({ clientes, columns, onEdit, onDelete, onSortChange }) => {

    return (
        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
                {clientes?.length ? (
                    <DataTable
                        items={clientes}
                        columns={columns}
                        idKey={'cliente_id'}
                        isActions={true}
                        color={{
                            'activo': 'badge-success',
                            'cortado': 'badge-danger',
                            'Sin registro': 'badge-secondary'
                        }}
                        renderActions={(id: number) => (
                            <div className='btn-group'>
                                <button
                                    className="btn btn-sm btn-primary"
                                    onClick={() => onEdit(id)}
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                </button>
                                <button
                                    className="btn btn-sm btn-danger"
                                    onClick={() => onDelete(id)}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </div>
                        )}
                        onSortChange={onSortChange}
                    />
                ) : (
                    <p style={{ marginTop: '30px', fontSize: '16px' }}>No hay clientes disponibles.</p>
                )}
            </div>
        </div>
    );
};

export default ClientesTable;
