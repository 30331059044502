import {URL_WEB_BASE} from "../config/config";
import {Column} from "../types/Columns";
/*
interface Column {
    id: number;
    label: string;
    key?: string;
    isImage?: boolean;
    urlImage?: string;
    shouldHaveBadge?: boolean;
    badgeType?: string;
}
*/


export const columnsUsuarios: Column[] = [
    {
        id: 1,
        label: 'Id',
        key: 'id',
        isVisible: true
    },
    {
        id: 2,
        label: 'Nombre',
        key: 'name',
        isVisible: true
    },
    {
        id: 3,
        label: 'Correo',
        key: 'email',
        isVisible: true
    },
    {
        id: 4,
        label: 'Acciones',
    }
]


export const columnsClientes: Column[] = [
    {
        id: 1,
        label: 'Nº',
        key: 'cliente_id',
        isVisible: true
    },
    {
        id: 2,
        label: 'RUT',
        key: 'rut',
        isVisible: true
    },
    {
        id: 3,
        label: 'Nombre Completo',
        key: 'nombre_completo',
        isVisible: true
    },
    {
        id: 4,
        label: 'Teléfono',
        key: 'telefono',
        isVisible: true
    },
    {
        id: 5,
        label: 'Direccion',
        key: 'todas_direcciones',
        isVisible: true
    },
    {
        id: 6,
        label: 'Estado Servicio',
        key: 'estado_servicio',
        badge: true,
        shouldHaveBadge: true
    }

];
export const columnsmonthlyConsumption: Column[] = [
    {
        id: 8,
        label: 'Nombre Cliente',
        key: 'nombre_cliente'
    },
    {
        id: 2,
        label: 'Dirección',
        key: 'direccion.calle'
    },

    {
        id: 5,
        label: 'Lectura Anterior',
        key: 'lectura_anterior'
    },
    {
        id: 6,
        label: 'Lectura Actual',
        key: 'lectura_actual'
    },
    {
        id: 7,
        label: 'Consumo del Mes (kWh)',
        key: 'consumo_kwh'
    },
    {
        id: 4,
        label: 'Fecha lectura',
        key: 'fecha_lectura_formateada'
    },
    {
        id: 3,
        label: 'Mes',
        key: 'mes_lectura'
    },
    {
        id: 3,
        label: 'Estado lectura',
        key: 'estado_lectura',
        badge: true,
        shouldHaveBadge: true,
    },

];



export const columnsInvoices: Column[] = [
    {
        id: 1,
        label: 'Id',
        key: 'INVOICE_ID'
    },
    {
        id: 2,
        label: 'Dirección',
        key: 'ADDRESS'
    },

    {
        id: 4,
        label: 'Consumo Actual',
        key: 'CONSUMPTION'
    },
    {
        id:5,
        label: 'Mes',
        key: 'MONTH'
    },
    {
        id:6,
        label: 'Año',
        key: 'YEAR'
    },
    {
        id:7,
        label: 'Monto',
        key: 'AMOUNT_DUE'
    },

    {
        id:9,
        label: 'Fecha de Vencimiento',
        key: 'DUE_DATE'
    },
    {
        id:10,
        label: 'Estado',
        key: 'STATUS',
        shouldHaveBadge: true,
        badge:true
    }
 ]
export const columnsTarifas: Column[] = [
    {
        id: 1,
        label: 'Id',
        key: 'tarifa_id'
    },
    {
        id: 2,
        label: 'Costo kw/h',
        key: 'costo_kw_format'
    },
    {
        id: 2,
        label: 'Tarifa Corte',
        key: 'tarifa_corte_format'
    },
    {
        id: 3,
        label: 'Tarifa Reposición',
        key: 'tarifa_reposicion_format'
    },
    {
        id: 3,
        label: 'Tarifa empalme',
        key: 'cargo_fijo_format'
    },
    //mes
    {
        id: 4,
        label: 'Mes',
        key: 'nombre_mes'
        //mes name functuion
    },
    //year
    {
        id: 4,
        label: 'Año',
        key: 'year'
    },
    //tipo_consumo
    {
        id: 5,
        label: 'Tipo Tarifa',
        key: 'tipo_tarifa'
    },

]
export const columnsFacturas = [
    {
        id: 1,
        label: 'Nº',
        key: 'numero_factura'
    },
    {
        id: 2,
        label: 'Nombre cliente',
        key: 'nombre_completo'
    },
    {
        id: 3,
        label: 'Dirección',
        key: 'direccion.calle'
    },
    {
        id: 4,
        label: 'Mes',
        key: 'mes'
    },
    {
        id: 5,
        label: 'Año',
        key: 'year'
    },
    {
        id: 6,
        label: 'Monto',
        key: 'monto_total_string'
    },
    {
        id: 7,
        label: 'Fecha de emisión',
        key: 'fecha_emision'
    },
    {
        id: 8,
        label: 'Fecha de Vencimiento',
        key: 'fecha_vencimiento'
    },
    {
        id: 9,
        label: 'Estado',
        key: 'estado',
        badge: true,
        shouldHaveBadge: true,
    }
];

export const columnsPagos = [
    {
        id: 1,
        label: 'Id',
        key: 'pago_id'
    },
    {
        id: 2,
        label: 'RUT',
        key: 'rut_cliente'
    },
    {
        id: 3,
        label: 'Nombre Completo',
        key: 'nombre_completo'
    },
    {
        id: 4,
        label: 'Monto Pagado',
        key: 'monto_pagado_string'
    },
    {
        id: 5,
        label: 'Fecha de Pago',
        key: 'fecha_pago'
    },
    {
        id: 6,
        label: 'Estado',
        key: 'estado_pago',
        badge: true,
        shouldHaveBadge: true
    }
]