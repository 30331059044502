//@ts-nocheck
// @ts-nocheck
import React from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

interface ClienteFormProps {
    onSubmit: (e: React.FormEvent) => void;
    errors: any;
    fields: any[];
    append: () => void;
    remove: (index: number) => void;
    isEdit: boolean;
}

const ClienteForm: React.FC<ClienteFormProps> = ({ onSubmit, errors, fields, append, remove, isEdit }) => {
    const { register, watch, setValue } = useFormContext();

    return (
        <form onSubmit={onSubmit}>
            {/* Sección Principal del Cliente */}
            <div className="row">
                <div className="col-md-6 mb-3">
                    <label className="form-label">RUT</label>
                    <input
                        type="text"
                        className="form-control form-control-solid"
                        {...register("rut", { required: true })}
                    />
                    {errors.rut && <span className="text-danger">Este campo es obligatorio</span>}
                </div>
                <div className="col-md-6 mb-3">
                    <label className="form-label">Nombre</label>
                    <input
                        type="text"
                        className="form-control form-control-solid"
                        {...register("nombre", { required: true })}
                    />
                    {errors.nombre && <span className="text-danger">Este campo es obligatorio</span>}
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <label className="form-label">Apellido</label>
                    <input
                        type="text"
                        className="form-control form-control-solid"
                        {...register("apellido", { required: true })}
                    />
                    {errors.apellido && <span className="text-danger">Este campo es obligatorio</span>}
                </div>
                <div className="col-md-6 mb-3">
                    <label className="form-label">Correo Electrónico</label>
                    <input
                        type="email"
                        className="form-control form-control-solid"
                        {...register("email", { required: true })}
                    />
                    {errors.email && <span className="text-danger">Este campo es obligatorio</span>}
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <label className="form-label">Teléfono</label>
                    <input
                        type="text"
                        className="form-control form-control-solid"
                        {...register("telefono", { required: true })}
                    />
                    {errors.telefono && <span className="text-danger">Este campo es obligatorio</span>}
                </div>
                <div className="col-md-6 mb-3">
                    <label className="form-label">Teléfono #2</label>
                    <input
                        type="text"
                        className="form-control form-control-solid"
                        {...register("telefono_dos")}
                    />
                </div>
            </div>

            {/* Sección de Direcciones */}
            <div className="col-md-12 mb-3">
                <label className="form-label">Direcciones</label>
                <div className="border-bottom mb-3"></div>
                {fields.map((field, index) => {
                    const requiereEmpalme = watch(`direcciones.${index}.requiere_empalme`, false);
                    const tipoPago = watch(`direcciones.${index}.tipo_pago`, "");
                    const montoTotal = watch(`direcciones.${index}.monto_total`, 0);
                    const cantidadCuotas = watch(`direcciones.${index}.cantidad_cuotas`, 1);
                    const abono = watch(`direcciones.${index}.abono`, 0);

                    // Calcular dinámicamente el valor de cuota
                    const valorCuota =
                        tipoPago === "cuota"
                            ? montoTotal / (cantidadCuotas || 1)
                            : tipoPago === "abono y cuota"
                                ? (montoTotal - abono) / (cantidadCuotas || 1)
                                : 0;

                    // Actualizar el valor de cuota en tiempo real
                    if (!isNaN(valorCuota)) {
                        setValue(`direcciones.${index}.valor_cuota`, valorCuota.toFixed(2));
                    }

                    return (
                        <div key={field.id} className="mb-3 p-3 border rounded">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Calle</label>
                                    <input
                                        type="text"
                                        placeholder="Calle"
                                        {...register(`direcciones.${index}.calle`, { required: true })}
                                        className="form-control form-control-solid mb-1"
                                    />
                                    {errors?.direcciones?.[index]?.calle && (
                                        <span className="text-danger">Este campo es obligatorio</span>
                                    )}
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Ciudad</label>
                                    <input
                                        type="text"
                                        placeholder="Ciudad"
                                        {...register(`direcciones.${index}.ciudad`, { required: true })}
                                        className="form-control form-control-solid mb-1"
                                    />
                                    {errors?.direcciones?.[index]?.ciudad && (
                                        <span className="text-danger">Este campo es obligatorio</span>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Provincia</label>
                                    <input
                                        type="text"
                                        placeholder="Provincia"
                                        {...register(`direcciones.${index}.estado`, { required: true })}
                                        className="form-control form-control-solid mb-1"
                                    />
                                    {errors?.direcciones?.[index]?.estado && (
                                        <span className="text-danger">Este campo es obligatorio</span>
                                    )}
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Número Cliente</label>
                                    <input
                                        type="text"
                                        placeholder="Número Cliente"
                                        {...register(`direcciones.${index}.numero_cliente`, { required: true })}
                                        className="form-control form-control-solid mb-1"
                                    />
                                    {errors?.direcciones?.[index]?.numero_cliente && (
                                        <span className="text-danger">Este campo es obligatorio</span>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Número de Medidor</label>
                                    <input
                                        type="text"
                                        placeholder="Número de Medidor"
                                        {...register(`direcciones.${index}.numero_medidor`, { required: true })}
                                        className="form-control form-control-solid mb-1"
                                    />
                                    {errors?.direcciones?.[index]?.numero_medidor && (
                                        <span className="text-danger">Este campo es obligatorio</span>
                                    )}
                                </div>
                            </div>

                            {/* Toggle Switch "Requiere Empalme" */}
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label className="form-label">Requiere Empalme</label>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`requiereEmpalme-${index}`}
                                            {...register(`direcciones.${index}.requiere_empalme`)}
                                        />
                                        <label className="form-check-label" htmlFor={`requiereEmpalme-${index}`}>
                                            {requiereEmpalme ? "Sí" : "No"}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {requiereEmpalme && (
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Tipo de Pago</label>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                id={`pagoUnico-${index}`}
                                                value="unico"
                                                {...register(`direcciones.${index}.tipo_pago`, { required: true })}
                                            />
                                            <label className="form-check-label" htmlFor={`pagoUnico-${index}`}>
                                                Pago Único
                                            </label>
                                        </div>
                                        <div className="form-check mt-2">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                id={`pagoCuota-${index}`}
                                                value="cuota"
                                                {...register(`direcciones.${index}.tipo_pago`, { required: true })}
                                            />
                                            <label className="form-check-label" htmlFor={`pagoCuota-${index}`}>
                                                Pago Cuota
                                            </label>
                                        </div>
                                        <div className="form-check mt-2">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                id={`pagoAbonoCuota-${index}`}
                                                value="abono y cuota"
                                                {...register(`direcciones.${index}.tipo_pago`, { required: true })}
                                            />
                                            <label className="form-check-label" htmlFor={`pagoAbonoCuota-${index}`}>
                                                Abono y Cuota
                                            </label>
                                        </div>
                                        {errors?.direcciones?.[index]?.tipo_pago && (
                                            <span className="text-danger">Seleccione un tipo de pago</span>
                                        )}
                                    </div>

                                    {tipoPago === "unico" && (
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Monto Pagado</label>
                                            <input
                                                type="number"
                                                step="0.01"
                                                className="form-control form-control-solid"
                                                {...register(`direcciones.${index}.monto_total`, {
                                                    required: true,
                                                    valueAsNumber: true,
                                                    min: 0,
                                                })}
                                            />
                                            {errors?.direcciones?.[index]?.monto_total && (
                                                <span className="text-danger">Este campo es obligatorio</span>
                                            )}
                                        </div>
                                    )}

                                    {tipoPago === "cuota" && (
                                        <>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Monto Total</label>
                                                <input
                                                    type="number"
                                                    step="0.01"
                                                    className="form-control form-control-solid"
                                                    {...register(`direcciones.${index}.monto_total`, {
                                                        required: true,
                                                        valueAsNumber: true,
                                                        min: 0,
                                                    })}
                                                />
                                                {errors?.direcciones?.[index]?.monto_total && (
                                                    <span className="text-danger">Este campo es obligatorio</span>
                                                )}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Cantidad de Cuotas</label>
                                                <input
                                                    type="number"
                                                    className="form-control form-control-solid"
                                                    {...register(`direcciones.${index}.cantidad_cuotas`, {
                                                        required: true,
                                                        valueAsNumber: true,
                                                        min: 1,
                                                    })}
                                                />
                                                {errors?.direcciones?.[index]?.cantidad_cuotas && (
                                                    <span className="text-danger">Este campo es obligatorio</span>
                                                )}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Valor de Cuota</label>
                                                <input
                                                    type="number"
                                                    className="form-control form-control-solid"
                                                    value={valorCuota}
                                                    disabled
                                                />
                                            </div>
                                        </>
                                    )}

                                    {tipoPago === "abono y cuota" && (
                                        <>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Monto Total</label>
                                                <input
                                                    type="number"
                                                    step="0.01"
                                                    className="form-control form-control-solid"
                                                    {...register(`direcciones.${index}.monto_total`, {
                                                        required: true,
                                                        valueAsNumber: true,
                                                        min: 0,
                                                    })}
                                                />
                                                {errors?.direcciones?.[index]?.monto_total && (
                                                    <span className="text-danger">Este campo es obligatorio</span>
                                                )}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Abono</label>
                                                <input
                                                    type="number"
                                                    step="0.01"
                                                    className="form-control form-control-solid"
                                                    {...register(`direcciones.${index}.abono`, {
                                                        required: true,
                                                        valueAsNumber: true,
                                                        min: 0,
                                                    })}
                                                />
                                                {errors?.direcciones?.[index]?.abono && (
                                                    <span className="text-danger">Este campo es obligatorio</span>
                                                )}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Cantidad de Cuotas</label>
                                                <input
                                                    type="number"
                                                    className="form-control form-control-solid"
                                                    {...register(`direcciones.${index}.cantidad_cuotas`, {
                                                        required: true,
                                                        valueAsNumber: true,
                                                        min: 1,
                                                    })}
                                                />
                                                {errors?.direcciones?.[index]?.cantidad_cuotas && (
                                                    <span className="text-danger">Este campo es obligatorio</span>
                                                )}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Valor de Cuota</label>
                                                <input
                                                    type="number"
                                                    className="form-control form-control-solid"
                                                    value={valorCuota}
                                                    disabled
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                            <div className="d-flex justify-content-end mt-3">
                                <button
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                    onClick={() => remove(index)}
                                >
                                    <FontAwesomeIcon icon={faTrash} /> Eliminar Dirección
                                </button>
                            </div>
                        </div>
                    );
                })}
                <button
                    type="button"
                    className="btn btn-primary btn-sm mt-3"
                    onClick={() =>
                        append({
                            calle: "",
                            ciudad: "",
                            estado: "",
                            numero_cliente: "",
                            numero_medidor: "",
                            requiere_empalme: false,
                            tipo_pago: "",
                            monto_total: 0,
                            cantidad_cuotas: 0,
                            valor_cuota: 0,
                            abono: 0,
                        })
                    }
                >
                    <FontAwesomeIcon icon={faPlus} /> Añadir Dirección
                </button>
            </div>

            <div className="d-flex justify-content-end mt-4">
                <button type="submit" className="btn btn-primary">
                    Guardar
                </button>
            </div>
        </form>
    );
};

export default ClienteForm;







