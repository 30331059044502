// @ts-nocheck
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { DataTable } from "../../components/ui/DataTable";
import { columnsPagos } from "../../data/Datos";
import {URL_BASE} from "../../config/config";

interface TableProps {
    pagos: Array<any>;
}

const Table: React.FC<TableProps> = ({ pagos }) => {
    const handleDownloadPdf = async (id: number) => {
        try {
            const response = await fetch(`${URL_BASE}/pagos/${id}/pdf`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                },
            });
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `voucher_${id}.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    const handleSendWhatsapp = (id: number) => {
        // Lógica para enviar por WhatsApp
    };

    return (
        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
                {pagos?.length ? (
                    <DataTable
                        items={pagos}
                        columns={columnsPagos}
                        idKey={'pago_id'}
                        color={{ 'pago total': 'badge-success', 'abono': 'badge-danger' }}
                        isActions={true}
                        renderActions={(id) => (
                            <div className='btn-group'>
                                <button
                                    className="btn btn-sm btn-danger"
                                    onClick={() => handleDownloadPdf(id)}
                                >
                                    <FontAwesomeIcon icon={faFilePdf} />
                                </button>

                            </div>
                        )}
                    />
                ) : (
                    <p style={{ marginTop: '30px', fontSize: '16px' }}>No hay pagos disponibles para este mes y año.</p>
                )}
            </div>
        </div>
    );
};

export default Table;
