// @ts-nocheck
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import Select from "react-select";
import { URL_BASE } from "../../config/config";

export interface IFormInput {
    lectura_id?: number;
    client_id: number;
    direccion_id: number;
    fecha_lectura: string;
    lectura_anterior: number;
    lectura_actual: number;
    consumo_kwh: number;
    estado_lectura: string;
}

interface FormularioProps {
    isEdit: boolean;
    onSubmit: (data: IFormInput) => void;
    errors: any;
    clientes: Array<any>;
    direcciones: Array<any>;
    handleClientChange: (clientId: number) => void;
}

const Formulario: React.FC<FormularioProps> = ({
                                                   isEdit,
                                                   onSubmit,
                                                   errors,
                                                   clientes,
                                                   direcciones,
                                                   handleClientChange,
                                               }) => {
    const { register, setValue, watch, trigger } = useFormContext();
    const selectedClientId = watch("client_id");
    const selectedDireccionId = watch("direccion_id");
    const lecturaAnterior = watch("lectura_anterior");
    const lecturaActual = watch("lectura_actual");
    const estadoLectura = watch("estado_lectura");

    const [selectedClientOption, setSelectedClientOption] = useState<any>(null);

    // Initialize selected client option when editing
    useEffect(() => {
        if (isEdit && selectedClientId) {
            const client = clientes.find(c => c.cliente_id === selectedClientId);
            if (client) {
                setSelectedClientOption({
                    value: client.cliente_id,
                    label: `${client.nombre} ${client.apellido}`,
                });
            }
        }
    }, [isEdit, selectedClientId, clientes]);

    useEffect(() => {
        if (isEdit && direcciones.length > 0 && selectedDireccionId) {
            // Forzar una actualización para que el select muestre el valor correcto
            setValue("direccion_id", selectedDireccionId);
        }
    }, [isEdit, direcciones, selectedDireccionId, setValue]);

    // Set fecha_lectura to today's date when creating a new record
    useEffect(() => {
        if (!isEdit) {
            const today = new Date().toISOString().substr(0, 10);
            setValue("fecha_lectura", today);
        }
    }, [isEdit, setValue]);

    // Load previous reading when client and address are selected, but only if not editing
    useEffect(() => {
        if (!isEdit && selectedClientId && selectedDireccionId) {
            axios
                .post(URL_BASE + "/lectura-anterior", {
                    cliente_id: selectedClientId,
                    direccion_id: selectedDireccionId,
                })
                .then((response) => {
                    const { lectura_anterior } = response.data;
                    setValue("lectura_anterior", lectura_anterior || 0);
                    trigger("lectura_anterior");
                })
                .catch((error) => {
                    console.error("Error al obtener la lectura anterior:", error);
                });
        }
    }, [isEdit, selectedClientId, selectedDireccionId, setValue, trigger]);

    // Calculate consumption automatically
    useEffect(() => {
        const anterior = parseFloat(lecturaAnterior);
        const actual = parseFloat(lecturaActual);
        if (!isNaN(anterior) && !isNaN(actual)) {
            const consumo = actual - anterior;
            setValue("consumo_kwh", consumo >= 0 ? consumo : 0);
            trigger("consumo_kwh");
        } else {
            setValue("consumo_kwh", 0);
        }
    }, [lecturaAnterior, lecturaActual, setValue, trigger]);

    // Control reading state and enable/disable actual reading
    useEffect(() => {
        if (estadoLectura === "Pendiente") {
            setValue("lectura_actual", 0);
            trigger("lectura_actual");
        }
    }, [estadoLectura, setValue, trigger]);

    return (
        <form onSubmit={onSubmit}>
            {/* Selección de Cliente */}
            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Cliente</label>
                <Select
                    options={clientes.map((cliente) => ({
                        value: cliente.cliente_id,
                        label: `${cliente.nombre} ${cliente.apellido}`,
                    }))}
                    value={selectedClientOption}
                    onChange={(selectedOption) => {
                        const selectedValue = selectedOption
                            ? selectedOption.value
                            : null;
                        setValue("client_id", selectedValue);
                        setSelectedClientOption(selectedOption);
                        handleClientChange(selectedValue!);
                    }}
                    isDisabled={isEdit}
                    isSearchable={true}
                    placeholder="Selecciona un cliente..."
                    noOptionsMessage={() => "No se encontraron opciones"}
                />
                {errors.client_id && (
                    <span className="text-danger">Este campo es obligatorio</span>
                )}
            </div>

            {/* Selección de Dirección */}
            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Dirección</label>
                <select
                    className="form-control"
                    value={selectedDireccionId || ""}
                    onChange={(e) => setValue("direccion_id", e.target.value)}
                >
                    <option value="">Selecciona una dirección...</option>
                    {direcciones.map((direccion) => (
                        <option key={direccion.direccion_id} value={direccion.direccion_id}>
                            {direccion.calle}
                        </option>
                    ))}
                </select>
                {errors.direccion_id && (
                    <span className="text-danger">Este campo es obligatorio</span>
                )}
            </div>

            {/* Fecha de Lectura */}
            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Fecha de Lectura</label>
                <input
                    type="date"
                    className="form-control form-control-solid"
                    {...register("fecha_lectura", { required: true })}
                    disabled={false}
                />
                {errors.fecha_lectura && (
                    <span className="text-danger">Este campo es obligatorio</span>
                )}
            </div>

            {/* Estado de Lectura */}
            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Estado de Lectura</label>
                <select
                    className="form-control"
                    {...register("estado_lectura", { required: true })}
                >
                    <option value="">Selecciona un estado...</option>
                    <option value="Pendiente">Pendiente</option>
                    <option value="Realizada">Realizada</option>
                </select>
                {errors.estado_lectura && (
                    <span className="text-danger">{errors.estado_lectura.message}</span>
                )}
            </div>

            {/* Lectura Anterior */}
            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Lectura Anterior (kWh)</label>
                <input
                    type="number"
                    className="form-control form-control-solid"
                    {...register("lectura_anterior", {
                        required: "Este campo es obligatorio",
                        min: { value: 0, message: "Debe ser un valor positivo" },
                    })}
                    disabled
                />
                {errors.lectura_anterior && (
                    <span className="text-danger">{errors.lectura_anterior.message}</span>
                )}
            </div>

            {/* Lectura Actual */}
            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Lectura Actual (kWh)</label>
                <input
                    type="number"
                    className="form-control form-control-solid"
                    {...register("lectura_actual", {
                        required: "Este campo es obligatorio",
                        min: { value: 0, message: "Debe ser un valor positivo" },
                    })}
                    disabled={estadoLectura === "Pendiente"}
                />
                {errors.lectura_actual && (
                    <span className="text-danger">{errors.lectura_actual.message}</span>
                )}
            </div>

            {/* Consumo Total */}
            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Consumo Total (kWh)</label>
                <input
                    type="number"
                    className="form-control form-control-solid"
                    {...register("consumo_kwh")}
                    disabled
                />
            </div>

            {/* Botón de Envío */}
            <div className="d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">Guardar</button>
            </div>
        </form>
    );
};

export default Formulario;

















